import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  serverUrl = localStorage.getItem('env') ? localStorage.getItem('env') : environment.serverUrl;

  constructor(private http: HttpClient) { }

  getHeader() {
    const authToken = localStorage.getItem('authToken');
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      })
    };
    return httpOptions;
  }

  getFileHeader() {
    const authToken = localStorage.getItem('authToken');
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${authToken}`,
      })
    };
    return httpOptions;
  }

  getCustomer(id) {
    return this.http.get(`${this.serverUrl}/customer/${id}`, this.getHeader())
      .pipe(map(response => response));
  }

  getCustomers() {
    return this.http.get(`${this.serverUrl}/customers`, this.getHeader())
      .pipe(map(response => response));
  }

  getUserCustomers() {
    return this.http.get(`${this.serverUrl}/getUserCustomers`, this.getHeader())
      .pipe(map(response => response));
  }

  selectCustomer(id) {
    return this.http.post(`${this.serverUrl}/customers`, { customerId: id }, this.getHeader())
      .pipe(map(response => response));
  }

  resetCustomer() {
    return this.http.put(`${this.serverUrl}/customers`, {}, this.getHeader())
      .pipe(map(response => response));
  }

  getGroupCustomer(id) {
    if(id) {
      return this.http.get(`${this.serverUrl}/customers?customerId=${id}`, this.getHeader())
      .pipe(map(response => response));
    } else {
      return this.http.get(`${this.serverUrl}/customers`, this.getHeader())
      .pipe(map(response => response));
    }
  }
  
  getGroupsOfCustomer(id) {
    return this.http.get(`${this.serverUrl}/customers/${id}`, this.getHeader())
      .pipe(map(response => response));
  }

  editGroupCustomer(id, data) {
    return this.http.put(`${this.serverUrl}/customers/${id}`, data, this.getHeader())
      .pipe(map(response => response));
  }

  createCustomer(id, data) {
    return this.http.post(`${this.serverUrl}/customers/${id}`, data, this.getHeader())
      .pipe(map(response => response));
  }

  deleteCustomer(id) {
    return this.http.delete(`${this.serverUrl}/customers/${id}`, this.getHeader())
      .pipe(map(response => response));
  }

  getEmailServerSetup() {
    return this.http.get(`${this.serverUrl}/customer-email`, this.getHeader())
      .pipe(map(response => response));
  }

  setEmailServerSetup(data) {
    return this.http.post(`${this.serverUrl}/customer-email`, data, this.getFileHeader())
      .pipe(map(response => response));
  }

  testEmailServerSetup(data) {
    return this.http.post(`${this.serverUrl}/test-customer-email`, data, this.getHeader())
      .pipe(map(response => response));
  }

  customerLevel() {
    return this.http.post(`${this.serverUrl}/customer-level`, {}, this.getHeader())
      .pipe(map(response => response));
  }

  getCustomersTree() {
    return this.http.get(`${this.serverUrl}/customers-tree`, this.getHeader())
      .pipe(map(response => response));
  }
}
