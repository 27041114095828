import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { Store } from '@ngrx/store';
import { UserState } from '../stores/user/user.reducer';
import { UserService } from './user.service';
import { tap } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    private router: Router,
    private userStore: Store<UserState>,
    private userService: UserService,) { }
  canActivate(route: ActivatedRouteSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      const decoded = jwtDecode(authToken);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        localStorage.removeItem('authToken');
        localStorage.removeItem('role');
        localStorage.removeItem('customerId');
        localStorage.removeItem('filteTerms');
        localStorage.removeItem('manageGroupQrfilteTerms');
        localStorage.removeItem('notificationMode');
        this.router.navigate(['/signin']);
      } else {
        this.userService.getUserPermissions()
          .subscribe((res: any) => {
            if (route.data[0] && route.data[0].path && res && res.permissions.indexOf(route.data[0].path[0]) === -1) {
              this.router.navigate(['/page-not-found']);
              return false;
            }
          });
        return true;
      }
    } else {
      this.router.navigate(['/signin']);
    }
  }
}
